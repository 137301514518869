import { useMemo } from 'react';
import { CardTarget } from 'flows/core/types/cards';
import { getUserInfo_getUser_user } from 'types/generated/getUserInfo';
import { GetSubdomain } from 'utils/queries';
import { getSubdomain } from 'types/generated/getSubdomain';
import {
  Division,
  HomeServicesReservation,
  Item,
} from '@updater/consumer-graph';
import { useQueryWhenAuthed } from 'utils/use-query-when-authed';
import { useSWRWhenAuthed } from 'utils/use-swr-when-authed';
import { ItemDefinition } from 'types';
import { useGetUserInfoQuery } from './use-get-user-info-query';
import { useFeature } from '@growthbook/growthbook-react';

type QueryData<T = {}> = {
  loading: boolean;
  data?: T;
  error?: any;
};

type ItemWithType = Item & { type: string };

export type DataForCards = {
  itemDefinitions: ItemDefinition[];
  items: ItemWithType[];
  user?: getUserInfo_getUser_user;
  division?: Division;
  moverAppSubdomain?: string | null;
  isGreystarPilotEnabled?: boolean;
};

export type TargetConfigType = { [key: number | string]: string };

export interface VerticalCardDetails {
  items: ItemWithType[];
  target: string | TargetConfigType;
  tviData?: HomeServicesReservation;
  attAchAutopayDiscountEnabled?: boolean;
}

export interface HomeServicesReservationVariables {
  id: string;
}

export const useDataForCards = (): QueryData<DataForCards> => {
  const { data: itemDefinitionsData, error: itemDefinitionsError } =
    useSWRWhenAuthed('/v2/item_definitions');
  const { data: itemsData, error: itemsError } = useSWRWhenAuthed('/v2/items');
  const { data: userData, error: userError } = useGetUserInfoQuery();
  const { data: subdomainData, error: subdomainError } =
    useQueryWhenAuthed<getSubdomain>(GetSubdomain);
  const { value: greystarAssurantPilotValue } = useFeature(
    'greystar_assurant_pilot_properties'
  );
  const isGreystarPilotEnabled = greystarAssurantPilotValue?.active === true;

  return useMemo(() => {
    const subdomainReady = !!subdomainData || !!subdomainError;
    if (itemsError || itemDefinitionsError || userError) {
      return {
        loading: false,
        error: itemsError || itemDefinitionsError || userError,
      };
    }
    if (itemDefinitionsData && itemsData && userData && subdomainReady) {
      return {
        loading: false,
        data: {
          itemDefinitions: itemDefinitionsData as ItemDefinition[],
          items: itemsData?.filter(({ move_id }: { move_id: number }) =>
            userData?.getUser?.user?.currentMove?.id
              ? move_id?.toString() === userData?.getUser?.user?.currentMove?.id
              : true
          ), // to get the current move items
          user: userData?.getUser?.user,
          moverAppSubdomain: subdomainData?.siteBranding?.subdomain,
          division: itemDefinitionsData?.find(
            (itemDefinition: ItemDefinition) => itemDefinition?.entity?.id
          )?.entity,
          isGreystarPilotEnabled,
        },
      };
    }
    return { loading: true };
  }, [
    subdomainData,
    subdomainError,
    itemsError,
    itemDefinitionsError,
    userError,
    itemDefinitionsData,
    itemsData,
    userData,
    isGreystarPilotEnabled,
  ]);
};

const convertRouteToUrlPath = (
  route: string,
  params?: Record<string, string | number | boolean>
) => {
  if (!params) return route;

  const appendedParams = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');
  return `${route}?${appendedParams}`;
};

export const buildURL = (action: CardTarget): string => {
  if (!action) return '';
  return action.type === 'external'
    ? action.url
    : convertRouteToUrlPath(action.route, action?.params);
};
