import styled from '@emotion/styled';
import {
  Flex,
  Image,
  useViewportSizes,
  UpdaterHeader,
  theme,
} from '@updater/ui-uds';
import { useAuth, useHeader } from 'context';
import React, { ReactNode, useMemo } from 'react';
import { useIsHomeOwner } from 'hooks/useIsHomeOwner';
import { useBrandedProModalQuery } from 'components/branded-pro-modal/BrandedProModalQuery';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useInviteToken } from 'flows/core/components/onboarding/common/hooks';
import { useTracking } from 'react-tracking';
import { useTrackOnMount } from 'utils/use-track-on-mount';
import { EntityLogo } from 'hooks/useCobrandLogo';
import { useIsPropertyValid } from 'hooks/use-is-property-valid';
import { StyledMenuLink } from 'components/styled-menu-link';
import { ProfileItem } from '@updater/ui-uds/dist/es/components/updater-header/ProfileMenu';
import { useFeature } from 'context/growthbook/useFeature';

// TODO: We should actually want to show for all kinds right?
const showLogosForKind = ['brokerage', 'mortgage', 'title'];

export const useEntityBrandings = () => {
  const inviteToken = useInviteToken();
  const { data, loading } = useBrandedProModalQuery({
    variables: {
      input: {
        inviteToken,
      },
    },
  });

  const { on: consumerBrandedHeaderFeatureEnabled } = useFeature(
    'consumer_entity_branded_header'
  );

  const { isMobile } = useViewportSizes();

  const entityBrandings = useMemo(() => {
    if (loading || !data?.siteBranding?.entityBrandings) {
      return [];
    }

    return data.siteBranding.entityBrandings
      .map((entity) => {
        return {
          logoUrl: entity?.logo?.url || '',
          kind: entity?.kind,
          showBranding:
            Boolean(entity?.showBranding) ||
            showLogosForKind.includes(entity?.kind ?? ''),
          name: entity?.displayName || entity?.name,
        };
      })
      .filter((branding): branding is EntityLogo => {
        const { showBranding, logoUrl, kind } = branding;
        // if the entity branding shouldn't show or the logo url is malformed don't display it
        if (
          !showBranding ||
          !logoUrl ||
          !logoUrl?.match(/^(http|https):\/\//) ||
          !kind ||
          !(kind.length > 0)
        ) {
          return false;
        }

        // most users will be caught by this as it should be on for everyone
        if (consumerBrandedHeaderFeatureEnabled) {
          return true;
        }

        // otherwise we know for a fact we always want to show for all bmt entities
        return showLogosForKind.includes(kind);
      });
  }, [
    consumerBrandedHeaderFeatureEnabled,
    data?.siteBranding?.entityBrandings,
    loading,
  ]);

  const shouldWrapBrandingContent = Boolean(
    entityBrandings.length > 1 && isMobile
  );

  const showUpdaterLogo = !loading && !entityBrandings.length;

  return {
    showUpdaterLogo,
    shouldWrapBrandingContent,
    entityBrandings,
    consumerBrandedHeaderFeatureEnabled,
  };
};

const Divider = styled.div`
  height: 20px;
  width: 1px;
  margin: 0 0.5rem;
  align-self: center;
  border-right: ${({ theme }) => `1px solid ${theme.colors.border}`};
`;

type EntityLogos = EntityLogo[];
export const EntityBrandings = ({
  entityBrandings,
  shouldWrapBrandingContent,
  brandingEnabled,
}: {
  entityBrandings: EntityLogos;
  shouldWrapBrandingContent: boolean;
  brandingEnabled: boolean;
}) => {
  const isHomeOwner = useIsHomeOwner();

  if ((!isHomeOwner && !brandingEnabled) || !entityBrandings?.length) {
    return null;
  }

  return (
    <Link href="/">
      <Flex
        gap={`${theme.space.xs}px`}
        data-testid="branding-images"
        flexWrap="wrap"
      >
        {entityBrandings.reduce((brandings, branding, index) => {
          const isLastEntity = index === entityBrandings.length - 1;
          brandings.push(
            <Image
              alt={`${branding.name} logo`}
              data-testid={`${branding.name}-logo`}
              key={`${branding.logoUrl}${index}`}
              src={branding.logoUrl}
              maxWidth={100}
              maxHeight={32}
            />
          );
          if (shouldWrapBrandingContent && !isLastEntity) {
            brandings.push(<Divider key={index} />);
          }
          return brandings;
        }, [] as Array<ReactNode>)}
      </Flex>
    </Link>
  );
};

export const PropertyContactMenuItem = () => {
  //  growthbook featue experiment
  const { value: enabled } = useFeature('consumer_property_contact');

  if (enabled) {
    return (
      <ProfileItem>
        <StyledMenuLink data-testid="property-contact" href="/property-contact">
          Property Contact
        </StyledMenuLink>
      </ProfileItem>
    );
  }

  return null;
};

export const ConsumerAppBrandedHeader = () => {
  const { userFirstName, userToAddressUnit, handleAuthClick } = useHeader();
  const { isLoggedIn } = useAuth();
  const router = useRouter();
  const {
    showUpdaterLogo,
    shouldWrapBrandingContent,
    entityBrandings,
    consumerBrandedHeaderFeatureEnabled,
  } = useEntityBrandings();
  const showPropertyContact = useIsPropertyValid();

  const { trackEvent } = useTracking({
    object: 'consumer_app_header',
    verb: 'displayed',
    details: {
      showUpdaterLogo,
      entityBrandings,
      brandingEnabled: consumerBrandedHeaderFeatureEnabled,
    },
  });
  useTrackOnMount(trackEvent);

  return (
    <UpdaterHeader
      displayName={userFirstName}
      displayUnit={userToAddressUnit}
      menuRequired
      isLoggedIn={isLoggedIn}
      onLogoClick={() => router.push('/')}
      onLogoutClick={() => handleAuthClick()}
      LinkWrapper={({ children: linkChildren, href = '' }) => (
        <StyledMenuLink href={href}>{linkChildren}</StyledMenuLink>
      )}
      PropertyContactMenuItem={
        showPropertyContact ? PropertyContactMenuItem : undefined
      }
      Content={() => (
        <EntityBrandings
          shouldWrapBrandingContent={shouldWrapBrandingContent}
          entityBrandings={entityBrandings}
          brandingEnabled={consumerBrandedHeaderFeatureEnabled}
        />
      )}
      showUpdaterLogo={showUpdaterLogo}
    />
  );
};
